<template>
  <div>
    <b-card-code :title="$t('Manage') + ' ' + $t('Spaces')">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between">
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-modal.modal-create-space
            @click="resetNewSpace()"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">{{$t('Add')}}</span>
          </b-button>
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{$t('Search')}}</label>
            <b-form-input v-model="searchTerm" :placeholder="$t('Search')" type="text" class="d-inline-block" />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :isLoading="loadingSpaces"
        :rows="spaces"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- Slot: Table Column -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span
            v-if="props.column.label ==='Name'"
            class="text-nowrap"
          >
            {{ $t('Name') }}
          </span>
          <span
            v-else-if="props.column.label ==='Zone'"
            class="text-nowrap"
          >
            {{ $t('Zone') }}
          </span>
          <span
            v-else-if="props.column.label ==='Scenes'"
            class="text-nowrap"
          >
            {{ $t('Scenes') }}
          </span>
          <span
            v-else-if="props.column.label ==='Parking Lot'"
            class="text-nowrap"
          >
            {{ $t('Parking Lot') }}
          </span>
          <span
            v-else-if="props.column.label ==='Action'"
            class="text-nowrap"
          >
            {{ $t('Action') }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <template slot="table-row" slot-scope="props">
          <!-- Column: No -->
          <span v-if="props.column.field === 'index'">
            {{ props.index + 1 }}
          </span>
          <!-- Column: Spaces -->
          <span v-else-if="props.column.field === 'scenes'">
            <div>
              <b-badge
                variant="info"
                v-for="sceneSpace in props.row.sceneSpaces"
                :key="sceneSpace.id"
                style="margin-right: 5px"
              >
                {{ sceneSpace.scene.name }}
              </b-badge>
            </div>
            <!-- {{ props.row.sceneSpaces }} -->
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'" class="action-btns">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-success"
              v-b-modal.modal-edit-space
              size="sm"
              @click="setEditSpace(props.row)"
            >
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span class="align-middle">{{$t('Edit')}}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              size="sm"
              @click="removeSpace(props.row)"
            >
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span class="align-middle">{{$t('Delete')}}</span>
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{$t('Showing')}} 1 {{$t('to')}} </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> {{$t('of')}} {{ props.total }} {{$t('entries')}} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>

    <!-- create space modal-->
    <b-modal
      id="modal-create-space"
      cancel-variant="outline-secondary"
      :ok-title="$t('Create')"
      :cancel-title="$t('Cancel')"
      :title="$t('Create') + ' ' + $t('Space')"
      centered
      no-close-on-backdrop
      ref="create-space-modal"
      :ok-disabled="createLoading"
      @ok="createSpace"
    >
      <validation-observer ref="createSpaceRules">
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <label for="name">{{$t('Name')}}</label>
              <b-form-input
                v-model="newSpace.spaceName"
                id="name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Space name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Principal"
              rules="required"
            >
              <label for="principal">{{$t('Principal')}}</label>
              <v-select
                id="principal"
                v-model="selectedPrincipal"
                :options="principals"
                label="name"
                @input="onPrincipalChange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="ParkingLot"
              rules="required"
            >
              <label for="parkingLot">{{$t('Parking Lot')}}</label>
              <v-select
                id="parkingLot"
                v-model="selectedParkingLot"
                :options="parkingLots"
                label="name"
                @input="onParkingLotChange"
                :disabled="selectedPrincipal == null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="scene">{{$t('Scenes')}}</label>
            <v-select
              id="scene"
              :value="selectedScenes"
              :options="scenes"
              label="name"
              multiple
              :placeholder="$t('Select') + ' ' + $t('Scenes')"
              :disabled="
                selectedPrincipal == null || selectedParkingLot == null
              "
              @input="onScenesChangeCreate"
            />
          </b-form-group>

          <!-- <b-form-group>
            <label for="scene">Address</label>
            <b-form-input v-model="newSpace.address" id="address" type="text" placeholder="Address" />
          </b-form-group> -->

          <b-form-group>
            <label for="address">{{$t('Address')}}</label>
            <b-input-group>
              <gmap-autocomplete
                ref="gmapAutocompleteCreate"
                class="form-control"
                :placeholder="$t('Search address')"
                @place_changed="usePlace"
                :options="{
                  fields: [
                    'geometry',
                    'formatted_address',
                    'address_components',
                  ],
                }"
                :select-first-on-enter="true"
              >
              </gmap-autocomplete>
            </b-input-group>
            <GmapMap
              ref="mapRefCreate"
              :center="centerMapCreate"
              :zoom="18"
              style="width: 100%; height: 400px"
              @click="handleMapClickOnCreate"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
              />
            </GmapMap>
          </b-form-group>

          <b-form-group>
            <label for="zone">{{$t('Zone')}}</label>
            <v-select
              id="zone"
              v-model="newSpace.zone"
              :options="zones"
              label="name"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- edit space modal-->
    <b-modal
      id="modal-edit-space"
      cancel-variant="outline-secondary"
      :ok-title="$t('Update')"
      :cancel-title="$t('Cancel')"
      :title="$t('Update') + ' ' + $t('Space')"
      centered
      no-close-on-backdrop
      ref="edit-space-modal"
      :ok-disabled="editLoading"
      @ok="updateSpace"
    >
      <validation-observer ref="editSpaceRules">
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <label for="name">{{$t('Name')}}</label>
              <b-form-input
                v-model="editSpace.newSpaceName"
                id="name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Space name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Principal"
              rules="required"
            >
              <label for="principal">{{$t('Principal')}}</label>
              <v-select
                id="principal"
                v-model="selectedPrincipal"
                :options="principals"
                label="name"
                @input="onPrincipalChange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="ParkingLot"
              rules="required"
            >
              <label for="parkingLot">{{$t('Parking Lot')}}</label>
              <v-select
                id="parkingLot"
                v-model="selectedParkingLot"
                :options="parkingLots"
                label="name"
                @input="onParkingLotChange"
                :disabled="selectedPrincipal == null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="scene">{{$t('Scenes')}}</label>
            <v-select
              id="scene"
              class="custom-diabled-select"
              :value="selectedScenes"
              :options="scenes"
              label="name"
              multiple
              :placeholder="$t('Select') + ' ' + $t('Scenes')"
              :disabled="
                selectedPrincipal == null || selectedParkingLot == null
              "
              @input="onScenesChangeEdit"
            />
          </b-form-group>

          <!-- <b-form-group>
            <label for="scene">Address</label>
            <b-form-input v-model="newSpace.address" id="address" type="text" placeholder="Address" />
          </b-form-group> -->
          <b-form-group>
            <label for="address">{{$t('Address')}}</label>
            <b-input-group>
              <gmap-autocomplete
                ref="gmapAutocompleteEdit"
                class="form-control"
                :placeholder="$t('Search address')"
                @place_changed="usePlace"
                :options="{
                  fields: [
                    'geometry',
                    'formatted_address',
                    'address_components',
                  ],
                }"
                :select-first-on-enter="true"
              >
              </gmap-autocomplete>
            </b-input-group>
            <GmapMap
              ref="mapRefEdit"
              :center="centerMapEdit"
              :zoom="18"
              style="width: 100%; height: 400px"
              @click="handleMapClickOnEdit"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
              />
            </GmapMap>
          </b-form-group>

          <b-form-group>
            <label for="zone">{{$t('Zone')}}</label>
            <v-select
              id="zone"
              v-model="editSpace.zone"
              :options="zones"
              label="name"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
  BForm,
  VBModal,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import vSelect from "vue-select";
import { gmapApi } from "vue2-google-maps";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      selectedPrincipal: null,
      selectedParkingLot: null,
      selectedScenes: [],
      newSpace: {
        spaceName: "",
        principal: "",
        parkingLot: "",
        scenes: [],
        zone: "",
      },
      editSpace: {
        newSpaceName: "",
        principal: "",
        parkingLot: "",
        scenes: [],
        zone: "",
      },
      createLoading: false,
      editLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        // {
        //   label: "No",
        //   field: "index",
        // },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Zone",
          field: "zone.name",
        },
        {
          label: "Scenes",
          field: "scenes",
        },
        {
          label: "Parking Lot",
          field: "parkingLot.name",
        },
        {
          label: "Action",
          field: "action",
          // width: "170px",
        },
      ],
      rows: [],
      searchTerm: "",
      mapCurrentPlace: null,
      markers: [],
      centerMapCreate: { lat: 45.508, lng: -73.587 },
      centerMapEdit: { lat: 45.508, lng: -73.587 },
      currentModal: null,

    };
  },
  computed: {
    scenes() {
      if (this.selectedParkingLot) {
        return this.$store.state.scene.scenes.filter(
          (sp) => sp.parkingLot?.id == this.selectedParkingLot?.id
        );
      }
      return this.$store.state.scene.scenes;
    },
    spaces() {
      const filtered = this.$store.state.space.spaces.filter((sc) => {
        return (
          (this.g_selectedParkingLot == null ||
            sc.parkingLot?.id == this.g_selectedParkingLot?.id) &&
          (this.g_selectedPrincipal == null ||
            sc.parkingLot?.principal?.id == this.g_selectedPrincipal?.id)
        );
      });
      return filtered;
    },
    loadingSpaces() {
      return this.$store.state.space.loadingSpaces;
    },
    principals() {
      return this.$store.state.principal.principals;
    },
    parkingLots() {
      if (this.selectedPrincipal) {
        return this.$store.state.parkingLot.parkingLots.filter(
          (pl) => pl.principal?.id == this.selectedPrincipal?.id
        );
      }
      return this.$store.state.parkingLot.parkingLots;
    },
    zones() {
      return this.$store.state.zone.zones;
    },
    google: gmapApi,
    g_selectedPrincipal() {
      return this.$store.state.global.selectedPrincipal;
    },
    g_selectedParkingLot() {
      return this.$store.state.global.selectedParkingLot;
    },
  },
  async created() {
    await this.$store.dispatch("scene/get", {bvToast: this.$bvToast});
    await this.$store.dispatch("zone/get", this.$bvToast);
    await this.$store.dispatch("principal/get", this.$bvToast);
    await this.$store.dispatch("parkingLot/get", this.$bvToast);
    await this.$store.dispatch("space/get", this.$bvToast);
  },
  methods: {
    usePlace(place) {
      console.log('usePlace', place)
      if (place) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()

        if (this.currentModal == 'create') {
          this.updateMarkers(this.$refs.mapRefCreate, lat, lng)
          this.centerMapCreate = { lat, lng }

          this.newSpace.address = place.formatted_address
          this.newSpace.gpsLocation = lat + ", " + lng;
        } else {
          this.updateMarkers(this.$refs.mapRefEdit, lat, lng)
          this.centerMapEdit = { lat, lng }

          this.editSpace.address = place.formatted_address
          this.editSpace.gpsLocation = lat + ", " + lng;
        }

      }
    },
    updateMarkers(mapRef, lat, lng) {
      const that = this;
      for (let i = 0; i < this.markers.length; i++) {
        const marker = this.markers[i];
        marker.setMap(null);
      }

      mapRef.$mapPromise.then((map) => {
        that.markers = [
          new that.google.maps.Marker({
            map: map,
            position: { lat, lng },
            title: "Selected location",
          }),
        ];
      })

    },
    handleMapClickOnCreate(param) {
      const that = this
      const geocoder = new this.google.maps.Geocoder();

      geocoder.geocode({
        'latLng': param.latLng
      }, function (results, status) {
        if (status == that.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            that.newSpace.address = results[0].formatted_address
            that.newSpace.gpsLocation = param.latLng.lat() + ", " + param.latLng.lng();
            that.updateMarkers(that.$refs.mapRefCreate, param.latLng.lat(), param.latLng.lng())
          }
        }
      });
    },
    handleMapClickOnEdit(param) {
      const that = this
      const geocoder = new this.google.maps.Geocoder();

      geocoder.geocode({
        'latLng': param.latLng
      }, function (results, status) {
        if (status == that.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            that.editSpace.address = results[0].formatted_address
            that.editSpace.gpsLocation = param.latLng.lat() + ", " + param.latLng.lng();
            that.updateMarkers(that.$refs.mapRefEdit, param.latLng.lat(), param.latLng.lng())
          }
        }
      });
    },
    onScenesChangeCreate(values) {
      this.selectedScenes = values;
      if(values.length) {
        this.newSpace.address = values[0].address
        this.newSpace.gpsLocation = values[0].gpsLocation
        this.$refs.gmapAutocompleteCreate.$el.value = values[0].address

        const segs = values[0].gpsLocation?.split(", ")
        if (segs.length >= 2) {
          this.centerMapCreate = {
            lat: parseFloat(segs[0]),
            lng: parseFloat(segs[1]),
          };

          this.markers = [
            new this.google.maps.Marker({
              map: this.$refs.mapRefCreate,
              position: {
                lat: this.centerMapCreate.lat,
                lng: this.centerMapCreate.lng,
              },
              title: "Selected location",
            }),
          ];
        }
      } else {
        this.newSpace.address = ""
        this.newSpace.gpsLocation = ""
        this.$refs.gmapAutocompleteCreate.$el.value = ""
        this.markers = []
      }
    },
    onScenesChangeEdit(values) {
      this.selectedScenes = values;
      if(values.length) {
        this.editSpace.address = values[0].address
        this.editSpace.gpsLocation = values[0].gpsLocation
        this.$refs.gmapAutocompleteEdit.$el.value = values[0].address
      } else {
        this.editSpace.address = ""
        this.editSpace.gpsLocation = ""
        this.$refs.gmapAutocompleteEdit.$el.value = ""
      }
    },
    onPrincipalChange(principal) {
      this.selectedParkingLot = null;
      this.selectedScenes = [];
    },
    onParkingLotChange(parkingLot) {
      this.selectedScenes = [];
    },
    handledSpaces(originSpaces) {
      originSpaces.map((item) => {
        if (item.sceneSpaces.length > 0) {
          item.scene = this.scenes.find(
            (iS) => iS.id == item.sceneSpaces[0].sceneId
          );
        }
      });

      return originSpaces;
    },
    createSpace(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (this.selectedPrincipal == null || this.selectedParkingLot == null) {
        this.$bvToast.toast(
          "Please select a Principal and a Parking Lot on topbar",
          {
            title: `Missing requireds`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          }
        );
        return;
      }
      this.$refs.createSpaceRules.validate().then((success) => {
        if (success) {
          this.createLoading = true;
          this.$store
            .dispatch("space/store", {
              ...this.newSpace,
              scenes: this.selectedScenes.map((sc) => sc.id),
              zone: this.newSpace.zone?.name,
              parkingLot: this.selectedParkingLot?.name,
              principal: this.selectedPrincipal?.name,
              address: this.$refs.gmapAutocompleteCreate.$el.value,
            })
            .then((response) => {

              this.$store.commit("space/ADD_NEW_SPACE", response.data);
              this.createLoading = false;
              this.$refs["create-space-modal"].hide();

              this.$bvToast.toast("Space created successfully", {
                title: `Success`,
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.createLoading = false;

              this.$bvToast.toast(err.message, {
                title: `Error occured`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-right",
              });
            });
        }
      });
    },
    resetNewSpace() {
      this.currentModal = 'create'

      this.newSpace = {
        spaceName: "",
        zone: "",
      };

      this.selectedParkingLot = this.g_selectedParkingLot;
      this.selectedPrincipal = this.g_selectedPrincipal;
      this.selectedScenes = [];
    },
    setEditSpace(row) {
      this.currentModal = 'update'
      this.editSpace = {
        newSpaceName: row.name,
        spaceName: row.name,
        address: row.address,
        gpsLocation: row.gpsLocation,
        // newParkingLot: row.parkingLot?.name,
        // newPrincipal: row.parkingLot?.principal?.name,
        parkingLot: row.parkingLot?.name,
        principal: row.parkingLot?.principal?.name,
        zone: row.zone,
      };
      this.selectedParkingLot = row.parkingLot;
      this.selectedPrincipal = row.parkingLot?.principal;
      this.selectedScenes = row.sceneSpaces.map((ss) => ss.scene);
      
      const segs = row.gpsLocation ? row.gpsLocation.split(", ") : [0, 0];
      if (row.gpsLocation && segs.length >= 2) {
        this.centerMapEdit = {
          lat: parseFloat(segs[0]),
          lng: parseFloat(segs[1]),
        };
      } else {
        this.centerMapEdit = this.centerMapCreate;
      }

      this.$gmapApiPromiseLazy().then(() => {
        this.markers = [
          new this.google.maps.Marker({
            map: this.$refs.mapRefEdit,
            position: this.centerMapEdit,
            title: "Selected location",
          }),
        ];
      })

      setTimeout(()=> {
        this.$refs.gmapAutocompleteEdit.$el.value = row.address
      }, 1000)

    },
    updateSpace(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.editSpaceRules.validate().then((success) => {
        if (success) {
          this.editLoading = true;
          this.$store
            .dispatch("space/store", {
              ...this.editSpace,
              scenes: this.selectedScenes.map((sc) => sc.id),
              zone: this.editSpace.zone?.name,
              newParkingLot: this.selectedParkingLot?.name,
              newPrincipal: this.selectedPrincipal?.name,
              address: this.$refs.gmapAutocompleteEdit.$el.value,
            })
            .then((response) => {
              this.$store.commit("space/EDIT_SPACE", response.data);
              this.editLoading = false;
              this.$refs["edit-space-modal"].hide();

              this.$bvToast.toast("Space updated successfully", {
                title: `Success`,
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.editLoading = false;

              this.$bvToast.toast(err.message, {
                title: `Error occured`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-right",
              });
            });
        }
      });
    },
    removeSpace(row) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Are you sure?'), {
          title: this.$t('Please Confirm'),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.$store
              .dispatch("space/remove", row)
              .then(async (response) => {
                this.$bvToast.toast("Space deleted successfully", {
                  title: `Success`,
                  variant: "success",
                  solid: true,
                });

                await this.$store.dispatch("space/get", this.$bvToast);
              })
              .catch((err) => {
                this.$bvToast.toast(err.message, {
                  title: `Error occured`,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-bottom-right",
                });
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

[dir] .vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}
.custom-diabled-select {
  .vs__selected {
    background-color: #7367f0 !important;
    cursor: auto !important;

    .vs__deselect {
      display: none !important;
    }
  }
  .vs__search {
    cursor: auto !important;
  }
}
</style>
